import React from "react";
import cables from "../../../static/telecommunications/cables.jpg";
import billing from "../../../static/telecommunications/billing.jpg";
import gpon from "../../../static/telecommunications/gpon.jpg";
import blueprint from "../../../static/telecommunications/blueprint.jpg";
import tv from "../../../static/telecommunications/tv.jpg";
import fiber from "../../../static/telecommunications/fiber.jpg";
import motorola from "../../../static/partners/motorola.png";
import hp from "../../../static/partners/hp.png";
import oracle from "../../../static/partners/oracle.png";

import Layout from "../../components/Layout/Layout";

const Telecommunications = () => {
  return (
    <Layout>
      <div className="page ">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-paragraph">INDUSTRY</h1>
            <h1 className="section-title-long">Telecommunications</h1>
            <p className="section-description">
              Telecommunications is one of our strongest sectors and we have
              accumulated a tremendous experience in all areas of communications
              technologies and related fields such as infrastructure
              construction.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // textAlign: 'center',
            }}
          >
            <i
              className="icon icon-signal-1"
              style={{ fontSize: "20rem", color: "#112a59" }}
            />
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Next-gen of Comms</h1>
            </div>
          </div>
          <div className="row page__section --grid-1-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                S&T Moldova has been a long-time partner with the largest
                telecommunications vendor in Moldova - Moldtelecom. We have
                successfuly implemented many projects together - from billing
                systems to GPON tehnologies to smart TV.
              </p>
              <p className="section-description">
                We have also developed a strong relationship with Orange Moldova
                - the largest cellular network in the country, and they have
                chosen S&T for extending their fiber optics network country wise
                in order to push for new digital television services.
              </p>
              <p className="section-description">
                Our success in this sector has been fueled by the long standing
                relationship with major vendors of telco equipment such as
                Huawei and Cisco. Our certified specialists are the first to
                learn and test the newest advancements in telco technologies and
                our clients are the first to put this technologies at work for
                their consumers.
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img className="section-description-image" src={cables} alt="" />
            </div>
          </div>
        </div>

        <div className="block--white">
          <div className="row  page__section --grid-2-1 --align-left">
            <div>
              <h1 className="section-sub-title">Our work with Moldtelecom</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={billing} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">
                    Integration and development
                  </span>
                  <p className="section-title-small --align-left ">
                    Billing System
                  </p>
                  <p className="section-description --align-left ">
                    Together with our partner MINDBill, we have successfuly
                    implemented a sophisticated billing system for Moldtelecom
                    that currently processes all their billing transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={gpon} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Telecommunications</span>
                  <p className="section-title-small --align-left ">
                    GPON technology
                  </p>
                  <p className="section-description --align-left ">
                    We have worked for many years on implementing and extending
                    GPON technologies and offering the end consumer high quality
                    internet.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={tv} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Digital television</span>
                  <p className="section-title-small --align-left ">Smart TV</p>
                  <p className="section-description --align-left ">
                    He helped Moldtelecom to build the best digital television
                    network in the country and we continue to support their
                    efforts in maintaining and extending their capabilities in
                    this area.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block--gray">
          <div className="row  page__section --grid-2-1 --align-left">
            <div>
              <h1 className="section-sub-title">
                Our work with Orange Moldova
              </h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1 --align-left">
            <div className="tile-container">
              <div className="studies-tile --align-left height-45">
                <img className="section-image" src={blueprint} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Fiber optics</span>
                  <p className="section-title-small --align-left ">
                    Residential fiber CAD and construction
                  </p>
                  <p className="section-description --align-left ">
                    We have provided turnkey solutions for building a new
                    network of digital television for Orange, including CAD,
                    legal compliance, infrastructure construction and even
                    negotiating with residential owners.
                  </p>
                  {/* <a href="/" className="learn-more">
                    LEARN MORE
                  </a> */}
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="studies-tile --align-left height-45">
                <img className="section-image" src={fiber} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Fiber optics</span>
                  <p className="section-title-small --align-left ">
                    Full stack of technologies for residential fiber
                  </p>
                  <p className="section-description --align-left ">
                    Together with our partners, we have assembled a
                    next-generation stack of technologies to support the vision
                    of our client in building a superior digital television
                    network.
                  </p>
                  {/* <a href="/" className="learn-more">
                    LEARN MORE
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Our Partners</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div
                className="partners-tile --align-left height-35"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="partners-image-container">
                  <img
                    style={{ height: "4rem" }}
                    className="partners-image"
                    src={motorola}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Motorola is the leading global supplier of TETRA equipment
                    and is our partner in Moldova. We supply Motorola equipment
                    to the Police, Border Police, Customs, 112 service, and
                    others.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="partners-tile --align-left height-35"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="partners-image-container">
                  <img
                    style={{ height: "7rem" }}
                    className="partners-image"
                    src={hp}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Through our partner HPE, we supply, configure and maintain
                    top of the line servers, storage and networking equipment
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="partners-tile --align-left height-35"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="partners-image-container">
                  <img
                    style={{ height: "2rem" }}
                    className="partners-image"
                    src={oracle}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Oracle databases are present in the wide majority of
                    government organizations and our specialists are always
                    ready to offer professional assistance in operating the
                    critical stores of data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Telecommunications;
